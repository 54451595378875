import React, { ComponentProps } from 'react';
import { PAGE_MAX } from '../../components/designsystem/Pagination';
import SearchList from '../../components/SearchList';
import Search from '../../components/Search';
import { GetStaticProps } from 'next';

const Index = (props: ComponentProps<typeof SearchList>) => (
    <Search
        amountPerPage={PAGE_MAX}
        ResultsComponent={SearchList}
        contentWrapperVariant="searchList"
        buttonTypes={['filters', 'map', 'sort']}
        {...props}
    />
);

export const getStaticProps: GetStaticProps = async () => ({
        props: {},
    });

export default Index;
