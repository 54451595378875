import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import { useThemeContext } from '@oberoninternal/travelbase-ds/hooks/useThemeContext';
import { useRouter } from 'next/router';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { useTenantContext } from '../context/TenantContext';
import { UnitAndTripSearchParamsInput } from '../generated/graphql';
import Pagination, { PAGE_MAX } from './designsystem/Pagination';
import WideGrid, { Cell } from './designsystem/WideGrid';
import ListPaginationLink from './ListPaginationLink';
import { ResultsProps } from './SearchResults';
import SearchAlternativeDates from './SearchAlternativeDates';
import SearchHit from './SearchHit';
import SearchSpecial, { getPropsForTripType } from './SearchSpecial';
import Stack from './Stack';

const useImageSizes = () => {
    const { mediaQueries } = useThemeContext();
    return `(min-width: ${mediaQueries.xxxl}) 25vw, (min-width: ${mediaQueries.xl}) 447px, (min-width: ${mediaQueries.l}) 30vw, (min-width: ${mediaQueries.m}) 447px, (min-width: ${mediaQueries.s}) 50vw, 100vw`;
};

const SearchList: FC<React.PropsWithChildren<ResultsProps>> = ({
    isLoading,
    data,
    currentPageIndex,
    pageCount,
    unitParams,
    activeSpecial,
    lastSearchParams,
    filtersActive,
    removeActiveFilters,
    searchType,
}) => {
    const hits = data ?? [];
    const intl = useIntl();
    const sizes = useImageSizes();
    const { brandConfig } = useTenantContext();
    const { LowSearchListing } = brandConfig.customContent ?? {};
    const router = useRouter();

    return (
        <Container>
            {activeSpecial && (
                <section>
                    {typeof activeSpecial === 'string' ? (
                        <SearchSpecial {...getPropsForTripType(activeSpecial, intl)} />
                    ) : (
                        <SearchSpecial {...activeSpecial.special} />
                    )}
                </section>
            )}
            {isLoading && (
                <WideGrid widths={[100, 100 / 2, 33.3]}>
                    {new Array(PAGE_MAX).fill(null).map((_, i) => (
                        <Cell key={i}>
                            <SearchHit loading />
                        </Cell>
                    ))}
                </WideGrid>
            )}
            {!isLoading && hits.length > 0 && (
                <WideGrid widths={[100, 100 / 2, 33.3]}>
                    {hits.map(hit => (
                        <Cell key={hit.rentalUnit.id}>
                            <SearchHit
                                rentalUnit={hit.rentalUnit}
                                trips={hit.trips}
                                activeSpecial={
                                    typeof activeSpecial !== 'string' ? activeSpecial?.special.id : undefined
                                }
                                unitParams={{ ...unitParams, searched: router.asPath.split('?')[1] }}
                                sizes={sizes}
                            />
                        </Cell>
                    ))}
                </WideGrid>
            )}
            {!isLoading && hits.length === 0 && (
                <section>
                    <Stack spacing={5}>
                        <Title>
                            <FormattedMessage defaultMessage="Geen accommodaties gevonden" />
                        </Title>
                        {filtersActive && (
                            <Button
                                style={{
                                    alignSelf: 'flex-start',
                                }}
                                variant="outline"
                                onClick={removeActiveFilters}
                                type="button"
                            >
                                <FormattedMessage defaultMessage="Filters verwijderen" />
                            </Button>
                        )}
                    </Stack>
                </section>
            )}
            <Pagination currentPageIndex={currentPageIndex} pagesCount={pageCount} LinkComponent={ListPaginationLink} />
            {!isLoading &&
                pageCount <= 1 &&
                searchType === 'static' &&
                lastSearchParams?.date &&
                lastSearchParams?.duration && (
                    <SearchAlternativeDates lastSearchParams={lastSearchParams as UnitAndTripSearchParamsInput} />
                )}
            {LowSearchListing && <LowSearchListing />}
        </Container>
    );
};

const Container = styled.div`
    width: 100%;
    margin-top: ${({ theme }) => theme.spacing['30_Small']};
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        margin-top: 0;
    }

    & > * + * {
        margin-top: 6.4rem;
    }

    padding-bottom: 3.2rem;
`;

export default SearchList;
