import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import { DeviceSize, useDeviceSize } from '@oberoninternal/travelbase-ds/context/devicesize';
import { Box, Flex } from '@rebass/grid';
import { gql } from '@apollo/client';
import addDays from 'date-fns/addDays';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import isBefore from 'date-fns/isBefore';
import isSameDay from 'date-fns/isSameDay';
import startOfToday from 'date-fns/startOfToday';
import Link, { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import React, { FC } from 'react';
import { useInView } from 'react-intersection-observer';
import { FormattedDate, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import parseToDateString from '../constants/parseToDateString';
import { DateHitCount, UnitAndTripSearchParamsInput, useUnitSearchAlternativeDatesQuery } from '../generated/graphql';
import Heading from './designsystem/Heading';
import Divider from './Divider';
import SearchDateHitCount from './SearchDateHitCount';
import Stack from './Stack';

export const query = gql`
    query UnitSearchAlternativeDates($params: UnitAndTripSearchParamsInput!) {
        unitSearchAlternativeDates(params: $params) {
            hits
            date
        }
    }
`;

interface Props {
    lastSearchParams: UnitAndTripSearchParamsInput;
}

const correctRange = (index: number, min: number, visible: number): [number, number] => {
    if (index >= min) {
        return [index - min, index + (visible - min)];
    }
    return [0, visible];
};

const getVisibleRange = (deviceSize: DeviceSize | null, index: number, count: number) => {
    if ((deviceSize === 'tablet' || deviceSize === 'laptop') && count >= 11) {
        return correctRange(index, 5, 11);
    }

    if (deviceSize === 'mobile' && count >= 9) {
        return correctRange(index, 4, 9);
    }

    return [0] as const;
};

const SearchAlternativeDates: FC<React.PropsWithChildren<Props>> = ({ lastSearchParams }) => {
    const { data, loading } = useUnitSearchAlternativeDatesQuery({
        variables: {
            params: lastSearchParams,
        },
    });
    const [inViewRef, isInView] = useInView({ triggerOnce: true });
    const { query: params, pathname } = useRouter();

    const deviceSize = useDeviceSize();

    if (loading || !data?.unitSearchAlternativeDates.length || !deviceSize) {
        return null;
    }
    const { unitSearchAlternativeDates } = data;

    const midDate = new Date(lastSearchParams.date);
    const dateHits = eachDayOfInterval({ start: addDays(midDate, -7), end: addDays(midDate, 7) }).reduce<
        DateHitCount[]
    >((acc, next) => {
        if (isBefore(next, startOfToday())) {
            return acc;
        }

        const foundHit = unitSearchAlternativeDates.find(dateHit => isSameDay(new Date(dateHit.date), next));

        if (!foundHit) {
            return [...acc, { date: parseToDateString(next), hits: 0 }];
        }

        return [...acc, foundHit];
    }, []);
    const index = dateHits.map(({ date }) => date).indexOf(lastSearchParams.date);
    const activeHit = dateHits[index];

    const range = getVisibleRange(deviceSize, index, dateHits.length);
    const inViewHits = dateHits.slice(...range);

    const mostHitsAvailable = Math.max(...dateHits.map(({ hits }) => hits));

    const getLinkProps = (date: string): LinkProps => ({
        href: {
            query: {
                ...params,
                arrivalDate: date,
                departureDate: parseToDateString(addDays(new Date(date), lastSearchParams.duration)),
            },
            pathname,
        },
        passHref: true,
        shallow: true,
        scroll: true,
    });

    return (
        <Container>
            <Divider my={6} />
            <Heading>
                <Title>
                    <FormattedMessage
                        defaultMessage="Voor {nights} nachten is er op de huidige aankomstdag weinig aanbod"
                        values={{ nights: lastSearchParams.duration }}
                    />
                </Title>
            </Heading>
            <Body>
                <FormattedMessage defaultMessage="Kies eventueel een andere aankomstdag voor meer aanbod" />
            </Body>

            <Box className="gt-s">
                <DaysGrid style={{ paddingTop: '4rem', overflow: 'hidden' }} ref={inViewRef}>
                    {inViewHits.map(({ date, hits }, i) => (
                        <Link key={date} {...getLinkProps(date)} legacyBehavior>
                            <SearchDateHitCount
                                animate={isInView}
                                variant="desktop"
                                hits={hits}
                                mostHits={mostHitsAvailable}
                                index={i}
                                active={date === activeHit?.date}
                            />
                        </Link>
                    ))}
                </DaysGrid>
                <Divider mt={0} mb={3} as="span" style={{ display: 'block' }} />

                <DaysGrid>
                    {inViewHits.map(({ date }) => (
                        <Flex key={date} flexDirection="column" alignItems="center">
                            <Weekday className="gt-xl">
                                <FormattedDate value={date} weekday="long" />
                            </Weekday>
                            <Weekday className="lt-xl">
                                <FormattedDate value={date} weekday="short" />
                            </Weekday>
                            <DayMonth>
                                <FormattedDate value={date} day="numeric" month="short" />
                            </DayMonth>
                        </Flex>
                    ))}
                </DaysGrid>
            </Box>
            <Stack className="lt-s" spacing={3} mt={4}>
                {inViewHits.map(({ date, hits }) => (
                    <Link key={date} {...getLinkProps(date)} legacyBehavior>
                        <SearchDateHitCount variant="mobile" hits={hits} active={date === activeHit?.date}>
                            <Flex key={date} flexDirection="column">
                                <Weekday>
                                    <FormattedDate value={date} weekday="long" />
                                </Weekday>
                                <DayMonth className="gt-s">
                                    <FormattedDate value={date} day="numeric" month="short" />
                                </DayMonth>
                                <DayMonth className="lt-s">
                                    <FormattedDate value={date} day="numeric" month="long" />
                                </DayMonth>
                            </Flex>
                        </SearchDateHitCount>
                    </Link>
                ))}
            </Stack>
            <Divider className="lt-s" />
        </Container>
    );
};

export default SearchAlternativeDates;

const Container = styled.div`
    grid-column: 1 / -1;
`;

const Weekday = styled(Body).attrs({ variant: 'tiny' })`
    color: ${({ theme }) => theme.colors.neutral['50']};

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        color: ${({ theme }) => theme.colors.neutral['40']};
    }
    font-size: 12px;
`;

const DayMonth = styled(Body)`
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        color: ${({ theme }) => theme.colors.neutral['50']};
        font-weight: 400;
    }
    font-weight: 600;
    font-size: 14px;
`;

const DaysGrid = styled.div`
    display: flex;
    > * {
        flex: 1;
        + * {
            margin-left: 1rem;
        }
    }
`;
