import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import React, { FC, ReactNode } from 'react';
import { FormattedMessage, IntlShape } from 'react-intl';
import styled from 'styled-components';
import Cell from './designsystem/Cell';
import Stack from './Stack';
import { Maybe } from '@oberoninternal/travelbase-ds/entities/Maybe';
import { TripTypeEnum } from '../generated/graphql';
import { lastminuteMessages } from '../constants/tripTypeMessages';

interface Props {
    name?: ReactNode;
    description?: ReactNode;
    landingPageUrl?: Maybe<string>;
}

export const getPropsForTripType = (tripType: TripTypeEnum, intl: IntlShape) => {
    switch (tripType) {
        case TripTypeEnum.LastMinute:
            return {
                name: intl.formatMessage(lastminuteMessages.name),
                description: intl.formatMessage(lastminuteMessages.description),
            };
        default:
            return undefined;
    }
};

const SearchSpecial: FC<React.PropsWithChildren<Props>> = ({ name, description, landingPageUrl }) => (
    <Cell gridColumn={['1 / -1', null, null, '1 / 9']}>
        <Stack spacing={[2, null, null, 5]} style={{ maxWidth: '93rem' }}>
            <Title variant="large">{name}</Title>
            <Body>{description}</Body>

            {landingPageUrl && (
                <Cell gridColumn={['1, -1', '1 / 4']}>
                    <SpecialsButton variant="outline">
                        <a href={landingPageUrl}>
                            <FormattedMessage defaultMessage="Lees de actievoorwaarden" />
                        </a>
                    </SpecialsButton>
                </Cell>
            )}
        </Stack>
    </Cell>
);

export default SearchSpecial;

const SpecialsButton = styled(Button)`
    width: 100%;
    font-weight: 700;
    border-radius: 0.8rem;
    margin-top: ${({ theme }) => theme.spacing['50_Semi']};

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        margin-top: 0;
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.xl}) {
        margin-top: ${({ theme }) => theme.spacing['50_Semi']};
    }

    a {
        color: currentColor;
        text-decoration: none;
    }
`;
