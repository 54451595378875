/* eslint-disable import/prefer-default-export */
import { defineMessages } from 'react-intl';

const lastminuteMessages = defineMessages({
    name: {
        defaultMessage: 'Lastminute voordeel',
    },
    description: {
        defaultMessage: `Op het laatste moment een vakantie of weekendje weg boeken?

            Texel heeft een uitgebreid aanbod last minute aanbiedingen, waarmee u profiteert van aantrekkelijke kortingen! Wat dacht u van een vakantiehuis op een bungalowpark of vakantiepark met zwembad?

            Of overnacht u liever in een luxe hotel? Op Texel is ook een ruime keuze aan stacaravans en chalets. Hier vindt u die gezellige Bed en Breakfast die precies bij u past.

            Wilt u Texel eens op een heel andere manier beleven, kijk dan bij de bijzondere overnachtingen, zoals een yurt of misschien wel een pipowagen. Dat is vrijheid: pak maar vast uw koffer en boek snel een last minute op Texel!

            VVV Texel toont hier alle last minute aanbiedingen overzichtelijk op basis van accommodatiesoort. U kunt in het zoekveld ook zoeken naar aankomst op langere termijn of naar accommodaties waarin honden welkom zijn.`,
    },
});

export { lastminuteMessages };
