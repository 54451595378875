import { UnreachableCaseError } from '@oberoninternal/travelbase-ds/entities/UnreachableCaseError';
import React, { FC, forwardRef, Ref } from 'react';
import styled, { css } from 'styled-components';

interface BaseProps {
    hits: number;
    active?: boolean;
    href?: string;
    onClick?: () => void;
}
interface MobileProps extends BaseProps {
    variant: 'mobile';
}

interface DesktopProps extends BaseProps {
    variant: 'desktop';
    index: number;
    mostHits: number;
    animate: boolean;
}

const SearchDateHitCount: FC<React.PropsWithChildren<MobileProps | DesktopProps>> = forwardRef(
    ({ children, href, onClick, ...props }, ref: Ref<HTMLAnchorElement>) => {
        const { hits, active } = props;
        const isDisabled = hits === 0;

        const commonProps = {
            disabled: isDisabled,
            href: !isDisabled ? href : undefined,
            onClick: !isDisabled ? onClick : undefined,
            active: !!active,
        };

        switch (props.variant) {
            case 'desktop': {
                const { mostHits, index, animate } = props;
                return (
                    <Desktop mostHits={mostHits} className="gt-s">
                        <Filler
                            ref={ref}
                            hits={hits}
                            animate={animate}
                            index={index}
                            mostHits={mostHits}
                            {...commonProps}
                        >
                            <CountWrapper>
                                <Count>{hits}</Count>
                            </CountWrapper>
                        </Filler>
                    </Desktop>
                );
            }
            case 'mobile': {
                if (isDisabled) {
                    return null;
                }
                return (
                    <Mobile ref={ref} className="lt-s" {...commonProps}>
                        {children}

                        <Count style={{ marginLeft: 'auto' }}>{hits}</Count>
                    </Mobile>
                );
            }
            default:
                throw new UnreachableCaseError(props);
        }
    }
);

export default SearchDateHitCount;

const CountWrapper = styled.div`
    width: 100%;
    position: absolute;
    left: 0;
    top: -1.4rem;
    display: flex;
    justify-content: center;
`;

const Count = styled.div`
    background: ${({ theme }) => theme.colors.secondary['30']};
    border-radius: 1.5rem;
    min-width: 4rem;
    color: ${({ theme }) => theme.colors.neutral['0']};
    font-weight: 500;
    display: flex;
    justify-content: center;
    font-size: 14px;
    height: 2.8rem;
    align-items: center;
`;

const computeHeight = (val: number) => `calc(1.6rem + ${val} * 20rem)`;

const Desktop = styled.div<{ mostHits: number }>`
    position: relative;
    max-height: 21.6rem;
    --totalHeight: 20rem;
    height: ${computeHeight(1)};
`;

const Mobile = styled.a<{ active: boolean }>`
    text-decoration: none !important;
    height: 5.6rem;
    background: ${({ theme }) => theme.colors.neutral['5']};
    border: 1px solid ${({ theme }) => theme.colors.neutral['20']};
    border-radius: 8px;
    display: flex;
    padding: 0 ${({ theme }) => theme.spacing['40_Standard']};
    align-items: center;

    ${({ active }) =>
        active &&
        css`
            background: ${({ theme }) => theme.colors.secondary['30']};
            p {
                color: white !important;
            }

            ${Count} {
                background: white;
                color: ${({ theme }) => theme.colors.secondary['30']};
            }
        `};
`;

interface FillerProps {
    hits: number;
    index: number;
    animate: boolean;
    mostHits: number;
    active: boolean;
    disabled: boolean;
}

const Filler = styled.a<FillerProps>`
    position: absolute;
    bottom: -1px;
    left: 0;
    background: ${({ active, theme }) => theme.colors.secondary[active ? '30' : '10']};
    --border-color: ${({ theme, active }) => (active ? 'transparant' : theme.colors.secondary['20'])};
    border-radius: 5px 5px 0 0;
    width: 100%;
    height: ${({ hits, mostHits }) => computeHeight(hits / mostHits)};
    box-shadow: inset 0 0 0 1px var(--border-color);

    transition: all 100ms ease-in-out;
    :hover {
        --border-color: ${({ theme }) => theme.colors.secondary['30']};
        box-shadow: inset 0 0 0 2px var(--border-color);

        ${Count} {
            transform: scale(1.1);
            transition: all 200ms ease-in-out;
        }
    }
    transform: translateY(calc(100% - 1.6rem));
    animation: ${({ animate }) => animate && 'fadein'} 1s forwards ease-in-out;
    animation-delay: ${({ index }) => `${index / 15}s`};

    @keyframes fadein {
        from {
            transform: translateY(calc(100% - 1.6rem));
        }
        to {
            transform: translateY(0);
        }
    }

    ${({ disabled, theme, active }) =>
        disabled &&
        css`
            background: ${theme.colors.neutral[active ? '30' : '20']};
            --border-color: ${active ? 'transparant' : theme.colors.neutral['20']};

            :hover {
                --border-color: ${active ? 'transparant' : theme.colors.neutral['30']};
            }

            ${Count} {
                background: ${theme.colors.neutral['30']};
            }
        `};
`;
